import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "./routes/error";
import Home from "./routes/home";
import Root from "./routes/root";
import Sample from "./routes/sample";
export default function Router() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Root />,
			errorElement: <Error />,
			children: [
				{
					index: true,
					element: <Home />
				},
				{
					path: "/sample/:tenantId",
					element: <Sample />
				}
			]
		}
	]);
	return (
		<RouterProvider router={router} />
	);
}

export function setDocumentTitle(subtitle) {
	document.title = subtitle ? subtitle + " | Lab Axiom" : "Lab Axiom";
}
