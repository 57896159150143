import { setDocumentTitle } from "../router";
import { useRouteError } from "react-router-dom";

export default function Error() {
	setDocumentTitle("Error");
	const error = useRouteError();
	console.error(error);
	return (
		<>
			<h1>Error</h1>
			<p>{error.statusText || error.message}</p>
		</>
	);
}