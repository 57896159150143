import { Button } from "@mui/material";
import { setDocumentTitle } from "../router";

const APP_URL = process.env.REACT_APP_URL;
const MAILTO_URL = "mailto:labaxiom@gmail.com?" +
	"subject=Lab%20Axiom%20Sign%20Up" +
	"&body=" +
	"Name%3A%20" +
	"%0D%0A" +
	"Organization%3A%20" +
	"%0D%0A" +
	"Phone%3A%20" +
	"%0D%0A" +
	"Email%3A%20";

export default function Home() {
	setDocumentTitle();
	return (
		<div style={{ background: "#6699CC", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
			<a href={APP_URL}>
				<img
					alt="Lab Axiom"
					src="/images/lab-axiom-logo-tall.svg"
					style={{ filter: "brightness(0) saturate(100%) invert(1)" }}
					width="300px"
				>
				</img>
			</a>
			<Button
				color="primary"
				href={APP_URL}
				style={{ marginTop: "16px", width: "300px" }}
				variant="contained"
			>
				Log In
			</Button>
			<Button
				color="secondary"
				href={MAILTO_URL}
				style={{ marginTop: "16px", width: "300px" }}
				target="_blank"
				variant="contained"
			>
				Sign Up
			</Button>
		</div>
	);
}