import React from "react";
import { Button, TextField, Grid2 as Grid } from "@mui/material";
import { Form } from "react-router-dom";
import "./sample.css";
import { NavigateNext as SaveIcon, Print as PrintIcon, RestartAlt as RestartIcon } from "@mui/icons-material";
import { setDocumentTitle } from "../router";
import { useParams } from "react-router-dom";

const APP_API_URL = process.env.REACT_APP_API_URL;

export default function Sample() {
	const [formCompleted, setFormCompleted] = React.useState(false);
	const [formDisabled, setFormDisabled] = React.useState(false);
	const title = "Sample Submission";
	const { tenantId } = useParams();
	const encodedLabLogo = window.location.search.substring(1).split("&").find(param => param.startsWith("labLogo="))?.split("=")[1];
	const labLogo = encodedLabLogo ? decodeURIComponent(encodedLabLogo) : null;
	const encodedLabName = window.location.search.substring(1).split("&").find(param => param.startsWith("labName="))?.split("=")[1];
	const labName = encodedLabName ? decodeURIComponent(encodedLabName) : null;
	const encodedLabAddress = window.location.search.substring(1).split("&").find(param => param.startsWith("labAddress="))?.split("=")[1];
	const labAddress = encodedLabAddress ? decodeURIComponent(encodedLabAddress) : null;
	const encodedLabEmail = window.location.search.substring(1).split("&").find(param => param.startsWith("labEmail="))?.split("=")[1];
	const labEmail = encodedLabEmail ? decodeURIComponent(encodedLabEmail) : null;
	const encodedLabPhone = window.location.search.substring(1).split("&").find(param => param.startsWith("labPhone="))?.split("=")[1];
	const labPhone = encodedLabPhone ? decodeURIComponent(encodedLabPhone) : null;
	const [customerEmail, setCustomerEmail] = React.useState("");
	const [sampleName, setSampleName] = React.useState("");
	const [sampleId, setSampleId] = React.useState("");
	const [sampleCode, setSampleCode] = React.useState("");
	const [sampleLotNumber, setSampleLotNumber] = React.useState("");
	const [sampleCreatedAt, setSampleCreatedAt] = React.useState("");
	const [sampleNote, setSampleNote] = React.useState("");
	setDocumentTitle(title);
	const handleFormSubmit = async (event) => {
		event.preventDefault();
		if (formDisabled) return;
		try {
			setFormDisabled(true);
			const formData = new FormData(event.target);
			const data = {};
			for (const [key, value] of formData.entries()) {
				data[key] = value;
			}
			console.log(data);
			const response = await fetch(APP_API_URL + "/sample/submit", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			});
			console.log(response);
			if (!response.ok) throw new Error("Response Not Okay");
			const json = await response.json();
			console.log(json);
			setSampleId(json.result.id);
			setSampleCode(getCode(json.result.createdAt, json.result.updatedAt));
			setSampleCreatedAt(json.result.createdAt ? json.result.createdAt : json.result.updatedAt);
			setFormCompleted(true);
		} catch (error) {
			console.error(error);
		} finally {
			setFormDisabled(false);
		}
	};
	if (formCompleted) {
		return (
			<Grid id="SampleSubmissionContainer" container rowSpacing={2} columnSpacing={4}>
				<SampleHeader labLogo={labLogo} labName={labName} title={title} />
				<Grid size={{ xs: 12, sm: 6 }}>
					<h2>Instructions</h2>
					<p>Thanks for providing information about your sample. Here are the next steps:</p>
					<ol>
						<li>Print this receipt.</li>
						<li>Sign the printed receipt.</li>
						<li>Send the signed receipt with your sample.</li>
					</ol>
				</Grid>
				<Grid size={{ xs: 12, sm: 6 }}>
					<h2>Lab</h2>
					<p><b>{labName}</b></p>
					{labAddress && (
						<div style={{ marginTop: "8px" }}>
							{labAddress.split(",").map((line, index) => (
								<span key={index}>{line}<br /></span>
							))}
						</div>
					)}
					{labEmail && (
						<div style={{ marginTop: "8px" }}>{labEmail}</div>
					)}
					{labPhone && (
						<div style={{ marginTop: "8px" }}>{labPhone}</div>
					)}
				</Grid>
				<Grid size={12}>
					<h2>Customer</h2>
					<p><b>{customerEmail}</b></p>
				</Grid>
				<Grid size={12}>
					<h2>Sample</h2>
				</Grid>
				<Grid size={{ xs: 12, sm: 6 }}>
					<div><b>{sampleName}</b></div>
					<div style={{ marginTop: "8px" }}>ID: {sampleId}</div>
					<div style={{ marginTop: "8px" }}>Code: {sampleCode}</div>
					{sampleLotNumber && (
						<div style={{ marginTop: "8px" }}>Lot Number: {sampleLotNumber}</div>
					)}
					<div style={{ marginTop: "8px" }}>Created: {sampleCreatedAt.split("T")[0]}</div>
				</Grid>
				<Grid size={{ xs: 12, sm: 6 }}>
					<div>Tests and Specifications Requested:</div>
					<div style={{ marginTop: "8px" }}>{sampleNote}</div>
				</Grid>
				<Grid className="sample-submission-agreement" size={12}>
					<h2>Agreement</h2>
					<p style={{ fontSize: "12px", lineHeight: "15px" }}>
						I, the undersigned, understand that {labName} will bill me for all
						tests that it performs on this sample, as described in this document.
						I agree to pay in full all bills for tests that {labName} performs
						on this sample and samples that I submit in the future.
						If I request changes after {labName} has begun tests that this document
						describes, I accept that I am still responsible to pay in full all
						bills for the tests that {labName} has already begun.
						I understand that {labName} may refuse to perform tests on any sample,
						that {labName} will not perform tests on this sample until I submit
						this signed document, and that if I do not wish to be bound by this
						agreement, I should not submit this sample or this document.
					</p>
				</Grid>
				<Grid className="sample-submission-agreement" size={{ xs: 12, sm: 6 }} style={{ borderBottom: "1px solid black" }}>
					Signature:<br /><br />
				</Grid>
				<Grid className="sample-submission-agreement" size={{ xs: 12, sm: 6 }} style={{ borderBottom: "1px solid black" }}>
					Date:<br /><br />
				</Grid>
				<Grid className="sample-submission-action" size={6} justifyContent="right" alignItems="center">
					<Button
						onClick={() => {
							window.print();
						}}
						startIcon={<PrintIcon />}
						sx={{ marginTop: "16px" }}
						variant="contained"
					>
						Print
					</Button>
				</Grid>
				<Grid className="sample-submission-action" size={6} justifyContent="left" alignItems="center">
					<Button
						onClick={() => {
							setSampleName("");
							setSampleId("");
							setSampleCode("");
							setSampleLotNumber("");
							setSampleCreatedAt("");
							setSampleNote("");
							setFormCompleted(false);
						}}
						startIcon={<RestartIcon />}
						sx={{ marginTop: "16px" }}
						variant="contained"
					>
						Reset
					</Button>
				</Grid>
			</Grid>
		);
	}
	return (
		<Form
			onKeyDown={(e) => {
				const elementType = document.activeElement.type;
				if (e.key === "Enter" && elementType !== "submit" && elementType !== "button" && elementType !== "textarea") e.preventDefault();
			}}
			onSubmit={handleFormSubmit}
		>
			<input type="hidden" name="tenantId" value={tenantId}></input>
			<Grid id="SampleSubmissionContainer" container spacing={2}>
				<SampleHeader labLogo={labLogo} labName={labName} title={title} />
				<Grid size={12}>
					<h2>Customer</h2>
				</Grid>
				<Grid size={12}>
					<TextField
						label="Customer Email"
						name="email"
						onChange={(e) => setCustomerEmail(e.target.value)}
						required
						type="email"
						value={customerEmail}
					></TextField>
				</Grid>
				<Grid size={12}>
					<h2>Sample</h2>
				</Grid>
				<Grid size={12}>
					<TextField
						label="Sample Name"
						name="name"
						onChange={(e) => setSampleName(e.target.value)}
						required
						type="text"
						value={sampleName}
					></TextField>
				</Grid>
				<Grid size={12}>
					<TextField
						label="Lot Number"
						name="lotNumber"
						onChange={(e) => setSampleLotNumber(e.target.value)}
						type="text"
						value={sampleLotNumber}
					></TextField>
				</Grid>
				<Grid size={12}>
					<TextField
						label="Tests and Specifications Requested"
						name="note"
						onChange={(e) => setSampleNote(e.target.value)}
						multiline
						rows={5}
						required
						type="text"
						value={sampleNote}
					></TextField>
				</Grid>
				<Grid size={12} display="flex" justifyContent="center" alignItems="center">
					<Button type="submit" disabled={formDisabled} startIcon={<SaveIcon />} variant="contained" sx={{ marginTop: "16px" }}>
						Next
					</Button>
				</Grid>
			</Grid>
		</Form>
	);
}

function SampleHeader(props) {
	const labLogo = props.labLogo;
	const labName = props.labName;
	const title = props.title;
	return (
		<Grid size={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
			<img id="SampleSubmissionLogo" src={labLogo} alt={labName} />
			<h1>{title}</h1>
		</Grid>
	);
}

function getCode(createdAt, updatedAt) {
	const date = new Date(createdAt ? createdAt : updatedAt);
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const hour = date.getHours();
	const minute = date.getMinutes();
	const second = date.getSeconds();
	return month.toString().padStart(2, "0") + day.toString().padStart(2, "0") +
		hour.toString().padStart(2, "0") + minute.toString().padStart(2, "0") + second.toString().padStart(2, "0");
}